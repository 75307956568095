import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form']

  submit(event) {
    event.preventDefault()

    const form = this.formTarget
    const submitButton = event.currentTarget

    // Disable the div temporarily to prevent double-clicks
    submitButton.classList.add('pointer-events-none', 'opacity-50')

    form
      .requestSubmit()
      .catch(() =>
        // If the submission fails, retry once
        form.requestSubmit()
      )
      .finally(() => {
        // Re-enable the div after submission (success or failure)
        submitButton.classList.remove('pointer-events-none', 'opacity-50')
      })
  }
}
