import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.disableTurboForHashLinks()
  }

  disableTurboForHashLinks() {
    document.querySelectorAll('a[href^="#"]').forEach(link => {
      if (!link.hasAttribute('data-turbo')) {
        link.setAttribute('data-turbo', 'false')
      }
    })
  }
}
