// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from '@hotwired/stimulus'
import $ from 'jquery'
import ApplicationController from './application_controller'
import 'select2/dist/js/select2.full.min'

import '../styles/pages.scss'

export default class extends ApplicationController {
  static targets = ['variant', 'variantOption', 'select']

  connect() {
    console.log('ProductVariant controller connected')
  }

  selectVariant(event) {
    event.preventDefault()

    const variants = document.querySelectorAll('[id*="variant"] label')
    Array.from(variants).forEach(el => {
      el.classList.remove('active')
    })
    this.variantTarget.classList.add('active')

    const { variantId, productId } = this.variantTarget.dataset
    const url = `/products/variants?product_id=${productId}&variant_id=${variantId}`

    fetch(url, {
      headers: {
        Accept: 'text/vnd.turbo-stream.html',
        'X-Requested-With': 'XMLHttpRequest',
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        return response.text()
      })
      .then(html => {
        Turbo.renderStreamMessage(html)
      })
      .catch(error => {
        console.error('Error updating variants:', error)
      })
  }

  selectVariantOption(event) {
    event.preventDefault()

    // Only target variant-option labels
    const variantOptions = document.querySelectorAll('label.variant-option')
    Array.from(variantOptions).forEach(el => {
      el.classList.remove('active')
    })

    // Add active class to clicked variant
    const target = event.currentTarget
    target.classList.add('active')

    // Get the image index from the clicked variant
    const { imageIndex } = target.dataset
    const divIndex = 0 // Assuming we're using the first swiper instance for now

    // Update the swiper slides
    if (window.productSwiper && window.productSwiper[divIndex]) {
      const { slider, thumb } = window.productSwiper[divIndex]
      slider.slideTo(imageIndex)
      thumb.slideTo(imageIndex)
    }

    // Update the hidden SKU input for the cart form
    const skuId = target.dataset.skuId
    const skuInput = document.querySelector('input[name="cart_item[product_sku_id]"]')
    if (skuInput) {
      skuInput.value = skuId
    }

    const { variantId } = target.dataset
    const url = `/products/sku_price?variant_id=${variantId}`

    // Make the request to update the price
    fetch(url, {
      headers: {
        Accept: 'text/vnd.turbo-stream.html',
        'X-Requested-With': 'XMLHttpRequest',
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        return response.text()
      })
      .then(html => {
        Turbo.renderStreamMessage(html)
      })
      .catch(error => {
        console.error('Error updating price:', error)
      })
  }

  select_device() {
    const productSelects = document.querySelectorAll("select[id^='selection_']")
    const productIds = Array.from(productSelects, product => product.value)
    Array.from(productSelects).forEach(el => {
      el.dataset.productIds = productIds.toString()
      console.log(`product_ids: ${el.dataset.productIds}`)
    })
    this.stimulate('ProductVariant#change_device')
  }
}
